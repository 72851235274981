.home {
  min-height: 100vh;
  padding-top: 12vh;
 
}

.homeComponent{
  backdrop-filter: blur(2px) saturate(200%);
  -webkit-backdrop-filter: blur(2px) saturate(200%);
  background-color: rgba(8, 34, 97, 0.41);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  min-height: 30vh;
 
  margin-inline: auto !important;
  color: white;
  .column{
      flex: 1;
     border-radius: 5px;
     margin-inline: 1px;
    
     width: 100%;
     margin: 1vh;
  }

  .column:first-child{
      background-color: rgba(0, 255, 255, 0.342);
  }
  .column:nth-child(2){
      background-color: grey;
  }

 
}



.component{
  backdrop-filter: blur(2px) saturate(200%);
  -webkit-backdrop-filter: blur(2px) saturate(200%);
  background-color: rgba(8, 34, 97, 0.41);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  min-height: 20vh;
 
  margin-inline: auto ;
  margin-block: 2vh;
  color: white;
  padding: 1vh;
 
}

.component h4{
  color: white;
}